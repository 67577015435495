import _ from 'underscore';

(function () {
	"use strict";
	angular.module("cardspotWeb.areas.lookupOrders")
    .controller("CardholderModal", CardholderModal);

	CardholderModal.$inject = ["$scope", "$uibModalInstance", "lookupValues", "REGION_TYPES", "preActivationService", "orderService"];

	function CardholderModal($scope, $uibModalInstance, lookupValues, REGION_TYPES, preActivationService, orderService) {
        $scope.REGION_TYPES = REGION_TYPES;
        $scope.isNewRecord = $scope.$resolve.params === undefined || $scope.$resolve.params === null;
        $scope.cardholder = {
            orderId: $scope.$resolve.params?.detail?.orderId,
            orderItemId: $scope.$resolve.params?.detail?.orderItemId,
            orderType: $scope.$resolve.params?.detail?.orderType,
            shippingCostTotal: $scope.$resolve.params?.detail?.shippingCostTotal,
            siteId: $scope.$resolve.params?.detail?.siteId,
            siteVersionId: $scope.$resolve.params?.detail?.siteVersionId,
            programId: $scope.$resolve.params?.detail?.programId,
            programUniqueTag: $scope.$resolve.params?.detail?.programUniqueTag,
            merchantGroupId: $scope.$resolve.params?.detail?.merchantGroupId,
            orderItemCardAmountCardholderId: $scope.$resolve.params?.detail?.orderItemCardAmountCardholderId,
            orderItemCardAmountId: $scope.$resolve.params?.detail?.orderItemCardAmountId,
            externalId: $scope.$resolve.params?.detail?.orderItemCardAmountCardholderId,
            cardAmount: $scope.$resolve.params?.detail?.cardAmount,
            firstName: $scope.$resolve.params?.detail?.firstName,
            lastName: $scope.$resolve.params?.detail?.lastName,
            address1: $scope.$resolve.params?.detail?.address1,
            address2: $scope.$resolve.params?.detail?.address2,
            city: $scope.$resolve.params?.detail?.city,
            region: !$scope.$resolve.params?.detail?.regionKey || !$scope.$resolve.params?.detail?.stateOrProvince
                ? null
                : {
                    regionKey: $scope.$resolve.params?.detail?.regionKey,
                    regionName: $scope.$resolve.params?.detail?.stateOrProvince
            },
            stateOrProvince: $scope.$resolve.params?.detail?.stateOrProvince,
            zipOrPostalCode: $scope.$resolve.params?.detail?.zipOrPostalCode,
            country: !$scope.$resolve.params?.detail?.country || !$scope.$resolve.params?.detail?.regionType
                ? null
                : {
                    countryKey: $scope.$resolve.params?.detail?.country,
                    countryName: $scope.$resolve.params?.detail?.country,
                    postalCodeRegexValidationPattern: postalCodeRegexByCountry($scope.$resolve.params?.detail?.country),
                    regionLabel: $scope.$resolve.params?.detail?.regionType,
                    regionType: $scope.$resolve.params?.detail?.regionType
            },
            phone: $scope.$resolve.params?.detail?.phone,
            personalMessage: $scope.$resolve.params?.detail?.personalMessage,
            location: $scope.$resolve.params?.detail?.location,
            department: $scope.$resolve.params?.detail?.department,
            email: $scope.$resolve.params?.detail?.email,
            code: $scope.$resolve.params?.detail?.code,
            regionType: $scope.$resolve.params?.detail?.regionType,
            currency: {
                alphaCode: $scope.$resolve.params?.detail?.currency?.alphaCode,
                amlActivationThresholdAmount: $scope.$resolve.params?.detail?.currency?.amlActivationThresholdAmount,
                currencySymbol: $scope.$resolve.params?.detail?.currency?.currencySymbol,
                defaultCultureName: $scope.$resolve.params?.detail?.currency?.defaultCultureName,
                description: $scope.$resolve.params?.detail?.currency?.description,
                numericCode: $scope.$resolve.params?.detail?.currency?.numericCode
            },
            isBulk : $scope.$resolve.params?.detail?.isBulk ? $scope.$resolve.params?.detail?.isBulk : $scope.$resolve.params?.detail?.orderType === "Digital" ? true : false
        }
        $scope.minIssueAmountForCardType;
        $scope.maxIssueAmountForCardType;

        init();

        function init() {
            lookupValues.getCountries()
            .then(function (data) {
                $scope.countries = data;
            });

            initRegions();
            getCardTypes($scope.cardholder.programUniqueTag);
        }

        function initRegions() {
            if ($scope.cardholder.country) {
                var countryKey = $scope.cardholder.country.countryKey;
                if (countryKey) {
                    lookupValues.getRegions(countryKey)
                        .then(function (regions) {
                            $scope.regions = regions;
                            $scope.showRegionSelection = $scope.cardholder.country && hasRegions() && $scope.cardholder.country.regionType !== REGION_TYPES.NONE;
                            $scope.cardholder.country.postalCodeRegexValidationPattern = postalCodeRegexByCountry($scope.cardholder.country.countryName);
                        });
                }
            }
        }

        function getCardTypes(programUniqueTag) {
            preActivationService.ensureMerchantGroupProgramInformation(programUniqueTag)
                .then(function(data) {
                    $scope.cardTypes = data.cardTypes;
                    minIssueAmountForCardType();
                    maxIssueAmountForCardType();
                });
        }

        function minIssueAmountForCardType() {
			var type = findCardTypeForName('Regular');
			$scope.minIssueAmountForCardType = type ? type.minimumIssueAmount : null;
		}

		function maxIssueAmountForCardType() {
			var type = findCardTypeForName('Regular');
			$scope.maxIssueAmountForCardType = type ? type.maximumIssueAmount : null;
		}

		function findCardTypeForName(cardTypeName) {
			return _.find($scope.cardTypes, function (aCardType) {
				return cardTypeName === aCardType.cardType;
			});
		}

        function hasRegions() {
            return angular.isArray($scope.regions) && $scope.regions.length > 0;
        }

        function postalCodeRegexByCountry(country) {
            orderService.getPostalCodeRegexByCountry(country);
        }

		$scope.ok = function () {
			$uibModalInstance.close($scope.cardholder);
		};

		$scope.cancel = function () {
			$uibModalInstance.dismiss("cancel");
		};

        $scope.countrySelectionChanged = function () {
            initRegions();
        }
	}
}());